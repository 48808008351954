import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { useEffect, useState } from 'react'
import ReactTable from 'core/ReactTable'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'
import {
  adminDashboardAPI,
  dashboardAPI,
  userActivateAPI,
  withdrawRequestAPI,
  checkPendingWithdraws,
  getAllUserWithdrawDetails,
  withdrawRequestUpdateByAdminAPI,
} from 'api/areoland'
import TableSkeleton from 'core/Skeleton/TableSkeleton'
import DateFormat from 'core/utils/DateFormat'
import { isEmptyObject } from 'core/utils/object_util'
import * as React from 'react'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import IN500Logo from '../../assets/indexx500_bsc_logo.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';


const Dashboard = () => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const { enqueueSnackbar } = useSnackbar()

  const userData = useSelector((state) => state.app.user)
  const [dashboardData, setDashboardData] = useState([])
  const [purchaseData, setPurchaseData] = useState([])
  const [withdrawData, setWithdrawData] = useState([])
  const [allWithdrawData, setallWithdrawData] = useState([])
  const [allUsersData, setAllUsersData] = useState([])
  const [isAdmin, setAdmin] = useState(false)
  const [walletAddr, setWalletAddr] = useState('')
  const [userTxHash, setUserTxHash] = useState('')
  const [isAnyWithdrawPending, setAnyWithdrawPending] = useState(false)
  const affiliate_user_column = [
    { title: 'Total tokens purchased', field: 'totalTokensPurchased' },
    { title: 'Token name purchased', field: 'tokensNamePurchased' },
    { title: 'URL Used to purchase', field: 'userURLtoPurchase' },
    {
      title: 'Purchased On',
      field: 'purchaseDate',
      render: (rowData) => <DateFormat date={rowData.purchaseDate} />,
    },
    { title: 'Payment type used', field: 'paymentTypeUsed' },
  ]
  const row = {
    content: "",
    clear: "both",
    display: "table"
  }
  const column = {
    float: "left",
    width: "33.33 %",
    padding: "5px"
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }
  const useBStyles = makeStyles({
    FourTimesLargeBtn: {
      padding: "32px 88px",
      fontSize: "3.75rem"
    }
  });
  const bclasses = useBStyles();
  const affiliate_users_column = [
    { title: 'Name', field: 'firstName' },
    { title: 'UserName', field: 'username' },
    { title: 'Email', field: 'email' },
    { title: 'User Role', field: 'role' },
    { title: 'Is User a Company', field: 'isCompany' },
    { title: 'Is User an Individual', field: 'isIndividual' },
    { title: 'Is User an Affiliate', field: 'isAffiliate' },
    {
      title: 'Is User active',
      field: 'isActive',
      render(h) {
        return (
          <Button
            classes={{ root: classes.FourTimesLargeBtn }}
            onClick={() => handleUpdate(h)}
            variant="contained"
            color="primary"
            size="large"
          >
            {h.isActive ? 'Active' : 'Inactive'}
          </Button>
        )
      },
      onclick: (e) => {
        console.log(e)
      },
    },
    { title: 'Primary Platform Selected', field: 'socialMedia' },
    { title: 'Primary Platform Username', field: 'socialMediaUsername' },
    { title: 'Time zone', field: 'timeZone' },
    { title: 'Country', field: 'country' },
    { title: 'platform Community', field: 'platformCommunity' },
    { title: 'Affiliate code', field: 'affiliateCode' },
    { title: 'Total Affiliate Count', field: 'affiliateCount' },
    { title: 'Total Indexx500 Purchased', field: 'totalIndexx500Purchased' },
    {
      title: 'Token IndexxCrypto Purchased',
      field: 'totalIndexxCryptoPurchased',
    },
    { title: 'Total IndexxUSD+ Purchased', field: 'totalIndexxUSDPPurchased' },
    {
      title: 'Purchase Details',
      field: 'purchaseDetails',
      render: (rowData) => {
        let output = []
        console.log(rowData?.purchaseDetails)
        if (rowData?.purchaseDetails) {
          rowData.purchaseDetails.map((item, index) => {
            output.push(
              <div>
                <p>Token Name: {item.tokensNamePurchased}</p>
                <p>Token Amount: {item.totalTokensPurchased}</p>
                <p>URL Used to purchase: {item.userURLtoPurchase}</p>
                <p>Payment type used: {item.paymentTypeUsed}</p>
                <p>Purchased On: {item.purchaseDate}</p>
              </div>,
            )
            return output
          })
        } else {
          ; <p>No Purchase Data Avaliable</p>
        }
      },
    },
    // {
    //   title: "User Last Login",
    //   field: "lastLogin",
    //   render: (rowData) => <DateFormat date={rowData.lastLogin} />,
    // },
    // {
    //   title: "User Created On",
    //   field: "userCreatedDate",
    //   render: (rowData) => <DateFormat date={rowData.userCreatedDate} />,
    // },
  ]

  const handleUpdate = async (e) => {
    // let newArray = this.state.data.filter((element)=>element.rowId === index)
    console.log(e)
    if (!e.isActive && e.role !== 'admin') {
      const response = await userActivateAPI(e.email, e.username)
      console.log(response)
      if (response.error === undefined) {
        alert(`User ${e.username} has been activated`)
      } else {
        alert(`User ${e.username} has not been activated`)
      }
    } else if (e.role === 'admin') {
      alert('User is admin and cannot be activated')
    } else {
      alert('User already active')
    }
  }

  const handleWithdrawUpdate = async (e) => {
    console.log(e)
    console.log(e.withdrawTxHash.length)
    console.log(userTxHash)
    if (userTxHash === 0) {
      alert('Withdraw Txhash is required')
    } else {
      if (
        e.isActive &&
        e.email !== undefined &&
        e.withdrawStatus === 'Pending' &&
        (userTxHash !== undefined || userTxHash.length === 0)
      ) {
        const response = await withdrawRequestUpdateByAdminAPI(
          e.email,
          e.withdrawAmount,
          e.withdrawTokenName,
          e.withdrawUniqueId,
          userTxHash,
        )
        console.log(response)
        // if (response.error === undefined) {
        //   alert(`Withdraw ${e.withdrawId} has been updated`)
        // } else {
        //   alert(`Withdraw ${e.withdrawId} has not been updated`)
        // }
      } else {
        alert('Withdraw is not pending. Already proccessed')
      }
    }
  }

  const useStyles = makeStyles(() => ({
    root: {},
    item: {
      display: 'flex',
      flexDirection: 'column',
    },
  }))
  const classes = useStyles()

  const affiliate_withdraw_column = [
    { title: 'Withdraw unique Id', field: 'withdrawUniqueId' },
    { title: 'Withdraw Amount', field: 'withdrawAmount' },
    {
      title: 'Withdraw Status',
      field: 'withdrawStatus',
      render(h) {
        return (
          <Button
            onClick={() => handleWithdrawUpdate(h)}
            variant="contained"
            color="primary"
          >
            {h.withdrawStatus === 'Pending' ? 'Pending' : 'Success'}
          </Button>
        )
      },
      onclick: (e) => {
        console.log(e)
      },
    },
    { title: 'Withdraw Type', field: 'withdrawType' },
    { title: 'Withdraw Address', field: 'withdrawAddress' },
    { title: 'Withdrawal User Email', field: 'email' },
    {
      title: 'Withdraw Tx Hash',
      field: 'withdrawTxHash',
      render: (rowData) => (
        <TextField
          defaultValue={rowData.withdrawTxHash}
          onChange={(e) => handleChangeUserTxHash(e.target.value)}
        />
      ),
    },
    { title: 'Withdraw Fee', field: 'withdrawFee' },
    { title: 'Withdraw Token Name', field: 'withdrawTokenName' },
    {
      title: 'Withdraw Date',
      field: 'withdrawDate',
      render: (rowData) => <DateFormat date={rowData.withdrawDate} />,
    },
    // {
    //   title: "Withdraw Request Date",
    //   field: "withdrawRequestDate",
    //   render: (rowData) => <DateFormat date={rowData.withdrawRequestDate} />,
    // },
    // {
    //   title: "Withdraw Complete Date",
    //   field: "withdrawCompleteDate",
    //   render: (rowData) => <DateFormat date={rowData.withdrawCompleteDate} />,
    // },
    // {
    //   title: "Withdraw Cancel Date",
    //   field: "withdrawCancelDate",
    //   render: (rowData) => <DateFormat date={rowData.withdrawCancelDate} />,
    // },
    // {
    //   title: "Withdraw Reject Date",
    //   field: "withdrawRejectDate",
    //   render: (rowData) => <DateFormat date={rowData.withdrawRejectDate} />,
    // },
    // {
    //   title: "Withdraw Pending Date",
    //   field: "withdrawPendingDate",
    //   render: (rowData) => <DateFormat date={rowData.withdrawPendingDate} />,
    // },
    // {
    //   title: "Withdraw Requested By",
    //   field: "withdrawRequestedBy",
    // },
    // {
    //   title: "Withdraw Completed By",
    //   field: "withdrawCompletedBy",
    // },
    // {
    //   title: "Withdraw Cancelled By",
    //   field: "withdrawCancelledBy",
    // },
    // {
    //   title: "Withdraw Rejected By",
    //   field: "withdrawRejectedBy",
    // },
    // {
    //   title: "Withdraw Pending By",
    //   field: "withdrawPendingBy",
    // },
    // {
    //   title: "Withdraw Requested By",
    //   field: "withdrawRequestedBy",
    // },
    // {
    //   title: "Withdraw Completed By",
    //   field: "withdrawCompletedBy",
    // },
    // {
    //   title: "Withdraw Cancelled By",
    //   field: "withdrawCancelledBy",
    // },
    // {
    //   title: "Withdraw Rejected By",
    //   field: "withdrawRejectedBy",
    // },
    // {
    //   title: "Withdraw Pending By",
    //   field: "withdrawPendingBy",
    // },
    // {
    //   title: "Withdraw Requested By",
    //   field: "withdrawRequestedBy",
    // },
    // {
    //   title: "Withdraw Completed By",
    //   field: "withdrawCompletedBy",
    // },
    // {
    //   title: "Withdraw Cancelled By",
    //   field: "withdrawCancelledBy",
    // {
    //   title: "Withdraw Status",
    //   field: "withdrawStatus",
    //   render: (rowData) => {
    //     if (rowData.withdrawStatus === "pending") {
    //       return (
    //         <Button
    //           variant="contained"
    //           color="primary"
    //           onClick={() => handleUpdate(rowData)}
    //         >
    //           Update
    //         </Button>
    //       );
    //     } else {
    //       return <div>{rowData.withdrawStatus}</div>;
    //     }
    //   },
    // },
    // {
    //   title: "withdraw Date",
    //   field: "withdrawDate",
    //   render: (rowData) => <DateFormat date={rowData.withdrawDate} />,
    // },
    //{ title: "Payment type used", field: "paymentTypeUsed" },
  ]

  const handleChangeUserTxHash = async(value) => {
    console.log(value)
    setUserTxHash(value)
  }

  //const totalEarn = dashboardData.reduce((r, i) => r + i.earn, 0);

  const loadDashboardData = async () => {
    // if (!dashboardData.length) {
    console.log('userData', userData)
    if (
      userData?.data?.role === undefined ||
      userData?.data?.role !== 'admin'
    ) {
      setAdmin(false)
      const response = await dashboardAPI(userData.data.username)
      console.log('response', response)

      if (response.status === 200) {
        console.log('response', response)
        console.log('response', response.data.affiliateCode)
        if (
          response.data.purchaseDetails !== null ||
          response.data.withdrawDetails !== undefined
        ) {
          console.log(' ia m here')
          setPurchaseData(response.data.purchaseDetails)
        }
        setDashboardData(response.data)
        if (
          response?.data?.withdrawDetails !== null &&
          response?.data?.withdrawDetails !== undefined
        ) {
          setWithdrawData(response.data.withdrawDetails)
        } else {
          console.log(response?.data?.withdrawDetails)
          setWithdrawData([])
        }
      } else {
        enqueueSnackbar(response.error.message, { variant: 'error' })
      }
    } else {
      setAdmin(true)
      const response = await adminDashboardAPI()
      const results = await getAllUserWithdrawDetails()
      setAllUsersData(response.data)
      setallWithdrawData(results.data)

      console.log('response', results)
    }
    //}
  }

  const getPendingWithdraw = async () => {
    const response = await checkPendingWithdraws(userData.data.email)
    console.log('response', response)
    if (response.isPendingWithdraws) {
      console.log('yes pending')
      setAnyWithdrawPending(true)
    } else {
      setAnyWithdrawPending(false)
    }
  }

  useEffect(() => {
    loadDashboardData()
    getPendingWithdraw()
  }, [])

  const requestIUSDPWithdraw = async (e) => {
    let res = window.confirm(
      `You are about to withdraw ${e} IUSD+ from your account. Please make sure your wallet address ${walletAddr} is correct before proceeding.`,
    )
    console.log(res)
    if (res) {
      const response = await withdrawRequestAPI(
        userData.data.email,
        e,
        'IndexxUSDP',
        walletAddr,
      )
      console.log('response', response)
      console.log('response data', response.data)
      if (response.data === undefined) {
        alert(response.message)
      } else {
        console.log('response data', response.data)
        //alert(response.message)
      }
      console.log('response', e, userData, response)
    }
  }

  const requestIn500Withdraw = async (e) => {
    let res = window.confirm(
      `You are about to withdraw ${e} In500 from your account. Please make sure your wallet address ${walletAddr} is correct before proceeding.`,
    )
    console.log(res)
    if (res) {
      const response = await withdrawRequestAPI(
        userData.data.email,
        e,
        'Indexx500',
        walletAddr,
      )
      console.log('response', response)
      console.log('response data', response.data)
      if (response.data === undefined) {
        alert(response.message)
      } else {
        console.log('response data', response.data)
        //alert(response.message)
      }
      console.log('response', e, userData, response)
    }
  }

  const requestINXCWithdraw = async (e) => {
    let res = window.confirm(
      `You are about to withdraw ${e} INXC from your account. Please make sure your wallet address ${walletAddr} is correct before proceeding.`,
    )
    console.log(res)
    if (res) {
      const response = await withdrawRequestAPI(
        userData.data.email,
        e,
        'IndexxCrypto',
        walletAddr,
      )
      console.log('response', response)
      console.log('response data', response.data)
      if (response.data === undefined) {
        alert(response.message)
      } else {
        console.log('response data', response.data)
        //alert(response.message)
      }
      console.log('response', e, userData, response)
    }
  }

  const handleChangeWalletAddress = async (e) => {
    setWalletAddr(e)
  }

  const onCopy = async() => {
    alert('Copied to clipboard');
  };
  /*
  const handleDrawerClose = () => {
    AppActions.toggleSideBar(false);
  };

  */
  // if (!dashboardData.length) {
  //   return null;
  // }

  return (
    <div>

      {/* If user if not admin */}
      {!isAdmin && dashboardData.isActive && (
        <div>
          <Card className={classes.root}>
            <CardActions style={{ flexFlow: 'column-reverse' }}>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Wallet address"
                  variant="outlined"
                  defaultValue={
                    dashboardData.affiliateUserWalletAddress
                      ? dashboardData.affiliateUserWalletAddress
                      : ''
                  }
                  onChange={(e) => handleChangeWalletAddress(e.target.value)}
                />
              </Box>

              <Grid container spacing={3} style={{ display: 'none' }}>
                <Grid xs>
                  <img alt="IN500" src={IN500Logo} width={200} height={200} />
                  <Button variant="contained" size="large">
                    Withdraw IUSD+
                  </Button>
                  <img alt="IN500" src={IN500Logo} width={200} height={200} />
                  <Button variant="contained" size="large">
                    Withdraw IUSD+
                  </Button>
                  <img alt="IN500" src={IN500Logo} width={200} height={200} />
                  <Button variant="contained" size="large">
                    Withdraw IUSD+
                  </Button>
                </Grid>
              </Grid>

              <Typography
                style={{ fontWeight: 700 }}
                color="textSecondary"
                gutterBottom
              >
                <label>
                  {' '}
                  Total Indexx500 Earned:{' '}
                  {parseFloat(
                    dashboardData?.totalIndexx500Purchased?.toFixed(2),
                  )}{' '}
                </label>{' '}
                <Button
                  variant="contained"
                  onClick={(e) =>
                    handleOpen(
                      requestIn500Withdraw(
                        parseFloat(
                          dashboardData?.totalIndexx500Purchased?.toFixed(2),
                        ),
                      ),
                    )
                  }
                  defaultValue={
                    dashboardData?.totalIndexx500Purchased
                      ? parseFloat(
                        dashboardData?.totalIndexx500Purchased?.toFixed(2),
                      )
                      : 0
                  }
                  disabled={isAnyWithdrawPending}
                >
                  Withdraw
                </Button>{' '}

                <label>
                  {' '}
                  Total IndexxCrypto Earned:{' '}
                  {parseFloat(
                    dashboardData?.totalIndexxCryptoPurchased?.toFixed(2),
                  )}{' '}
                </label>
                <Button
                  variant="contained"
                  onClick={(e) =>
                    requestINXCWithdraw(
                      parseFloat(
                        dashboardData?.totalIndexxCryptoPurchased?.toFixed(2),
                      ),
                    )
                  }
                  disabled={isAnyWithdrawPending}
                  defaultValue={
                    dashboardData?.totalIndexxCryptoPurchased
                      ? parseFloat(
                        dashboardData?.totalIndexxCryptoPurchased?.toFixed(2),
                      )
                      : 0
                  }
                >
                  Withdraw
                </Button>{' '}
                <label>
                  {' '}
                  Total IndexxUSD+ Earned:{' '}
                  {parseFloat(
                    dashboardData?.totalIndexxUSDPPurchased?.toFixed(2),
                  )}{' '}
                </label>{' '}
                <Button
                  variant="contained"
                  onClick={(e) =>
                    requestIUSDPWithdraw(
                      parseFloat(
                        dashboardData?.totalIndexxUSDPPurchased?.toFixed(2),
                      ),
                    )
                  }
                  defaultValue={
                    dashboardData?.totalIndexx500Purchased
                      ? parseFloat(
                        dashboardData?.totalIndexx500Purchased?.toFixed(2),
                      )
                      : 0
                  }
                  disabled={isAnyWithdrawPending}
                >
                  Withdraw
                </Button>{' '}
                <br></br>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Text in a modal
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      Duis mollis, est non commodo luctus, nisi erat porttitor
                      ligula.
                    </Typography>
                  </Box>
                </Modal>
              </Typography>


            </CardActions>

            <CardHeader
              subheader={
                <>
                  <Typography>
                    Share affiliate links and earn <b>50%</b> on every purchase
                    of token by the users. Below are your affiliate links
                  </Typography>
                  <br />

                  <Typography style={{ display: 'none' }}>
                    <label> Below are the test URLs to be used for testing</label>
                    <br></br>
                    For Test Indexx500(IN500) token:{' '}
                    {`https://cerulean-squirrel-daa863.netlify.app/?referralcode=${dashboardData.affiliateCode}`}
                    <br></br>
                    For Test IndexxUSD+(IUSD+) token:{' '}
                    {`https://stalwart-elf-593a56.netlify.app/?referralcode=${dashboardData.affiliateCode}`}
                  </Typography>
                  <Typography>
                    <label style={{ display: 'none' }}>
                      {' '}
                      Below are the actual URLs to be shared to your friends or
                      relatives to purchase tokens
                    </label>{' '}
                    <br></br>

                    For IndexxCrypto(INXC) &nbsp; &nbsp; &nbsp;
                    {`https://inxc.indexx.ai/?referralcode=${dashboardData.affiliateCode}`}
                    {/* <CopyToClipboard text={`https://inxc.indexx.ai/?referralcode=${dashboardData.affiliateCode}`} onCopy={onCopy()}>
                    </CopyToClipboard> */}
                    <br></br>
                    For Indexx500(IN500) &nbsp; &nbsp; &nbsp;
                    {`https://in500.indexx.ai/?referralcode=${dashboardData.affiliateCode}`}
                    <br></br>
                    For IndexxUSD+(IUSD+) &nbsp; &nbsp; &nbsp;
                    {`https://iusdp.indexx.ai/?referralcode=${dashboardData.affiliateCode}`}
                    <br></br>
                  </Typography>
                </>
              }
              title="Affiliate"
            />
            <br></br>
            <br></br>
            <Divider />
            {purchaseData.length > 0 && (
              <CardContent style={{ padding: 0 }}>
                <Grid container spacing={6} wrap="wrap">
                  <Grid className={classes.item} item xs={12}>
                    {isEmptyObject(purchaseData) ? (
                      <TableSkeleton cols={5} rows={5} />
                    ) : (
                      <ReactTable
                        options={{
                          search: false,
                        }}
                        columns={affiliate_user_column}
                        data={purchaseData}
                        title="Affiliated Users Purchase Details"
                      />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            )}
            <Divider />
            <Divider />
            <Divider />
            <Divider />
            <Divider />
            <Divider />
            <Divider />
            <Divider />

            {withdrawData.length > 0 && (
              <CardContent style={{ padding: 0 }}>
                <Grid container spacing={6} wrap="wrap">
                  <Grid className={classes.item} item xs={12}>
                    {isEmptyObject(withdrawData) ? (
                      <TableSkeleton cols={5} rows={5} />
                    ) : (
                      <ReactTable
                        options={{
                          search: false,
                        }}
                        columns={affiliate_withdraw_column}
                        data={withdrawData}
                        title="Affiliated Users Withdraw Details"
                      />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            )}
          </Card>
        </div>
      )}

      {/* If user is admin */}
      {isAdmin && (
        <div>
          <Card className={classes.root}>
            <CardContent style={{ padding: 0 }}>
              <Grid container spacing={6} wrap="wrap">
                <Grid className={classes.item} item xs={12}>
                  {isEmptyObject(allUsersData) ? (
                    <TableSkeleton cols={5} rows={5} />
                  ) : (
                    <ReactTable
                      options={{
                        search: false,
                      }}
                      columns={affiliate_users_column}
                      data={allUsersData}
                      title="All Users Details"
                    />
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Divider />
            <Divider />
            <Divider />
            <Divider />
            <Divider />
            <Divider />
            <Divider />
            <Divider />
            <Divider />
            {allWithdrawData.length > 0 && (
              <CardContent style={{ padding: 0 }}>
                <Grid container spacing={6} wrap="wrap">
                  <Grid className={classes.item} item xs={12}>
                    {isEmptyObject(allWithdrawData) ? (
                      <TableSkeleton cols={5} rows={5} />
                    ) : (
                      <ReactTable
                        options={{
                          search: false,
                        }}
                        columns={affiliate_withdraw_column}
                        data={allWithdrawData}
                        title="All Affiliated Users Withdraw Details"
                      />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            )}
          </Card>
        </div>
      )}
    </div>
  )
}

export default Dashboard

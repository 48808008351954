import React from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const Help = () => {
  return (
    <div>
      <Typography variant="h1">Help & Support</Typography>
      <Divider />

      <Typography>Please contact to the support to get paid.</Typography>
      <Typography>Email: support@indexx.com</Typography>
    </div>
  );
};

export default Help;

import axios from "axios";

let baseURL = "";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  baseURL = "http://localhost:5000";
} else {
  baseURL = "https://test.api.affiliate.indexx.ai"; //"https://api-dot-areoland.appspot.com";
}

const API = axios.create({
  baseURL: baseURL,
});

export const signupAPI = async (name, email, password) => {
  try {
    const result = await API.post("/api/v1/addaffiliateuser", {
      name,
      email,
      password,
    });
    return result.data;
  } catch (e) {
    console.log("FAILED: unable to perform API request (signupAPI)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const signinAPI = async (email, password) => {
  try {
    const result = await API.post("/api/v1/login", {
      email,
      password,
    });
    console.log(result);
    return result.data;
  } catch (e) {
    console.log("FAILED: unable to perform API request (signinAPI)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const dashboardAPI = async (username) => {
  try {
    const result = await API.get(`/api/v1/affiliateusers/${username}`);
    return result.data;
  } catch (e) {
    console.log("FAILED: unable to perform API request (dashboardAPI)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};


export const adminDashboardAPI = async () => {
  try {
    const result = await API.get(`/api/v1/users`);
    return result.data;
  } catch (e) {
    console.log("FAILED: unable to perform API request (dashboardAPI)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const userActivateAPI = async (email, username) => {
  try {
    const result = await API.post(`/api/v1/activate/affiliateuser`, 
      {
        email,
        username,
    });
    return result.data;
  } catch (e) {
    console.log("FAILED: unable to perform API request (dashboardAPI)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const withdrawRequestAPI = async (email, withdrawAmount, tokenNameToWithdraw, withdrawAddress) => {
  try {
    const result = await API.post(`/api/v1/update/withdraw`, 
      {
        email,
        withdrawAmount,
        tokenNameToWithdraw,
        withdrawAddress
    });
    console.log(result);
    return result.data;
  } catch (e) {
    console.log("FAILED: unable to perform API request (dashboardAPI)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const withdrawRequestUpdateByAdminAPI = async (email, withdrawAmount, withdrawTokenName, withdrawUniqueId, withdrawTxHash) => {
  try {
    /*
    console.log('id',req.body.withdrawUniqueId);
    console.log('email',req.body.email);
    console.log('wigh',req.body.withdrawTokenName);
    console.log('a',req.body.withdrawAmount);

    */
    const result = await API.post(`/api/v1/update/withdrawStatus`, 
      {
        email,
        withdrawAmount,
        withdrawTokenName,
        withdrawUniqueId,
        withdrawTxHash
    });
    console.log(result);
    return result.data;
  } catch (e) {
    console.log("FAILED: unable to perform API request (dashboardAPI)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};


export const checkPendingWithdraws = async(email) =>{
  try {
    const result = await API.get(`/api/v1/check/pendingwithdraws/${email}`);
    console.log(result);
    return result.data;
  } catch (e) {
    console.log("FAILED: unable to perform API request (dashboardAPI)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
}

export const getAllUserWithdrawDetails = async() =>{
  try {
    const result = await API.get(`/api/v1/get/allwithdraws`);
    console.log(result);
    return result.data;
  } catch (e) {
    console.log("FAILED: unable to perform API request (dashboardAPI)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
}